import logo from './assets/logo.PNG';
import body from './assets/body1.jpg';
import ss from './assets/ss.PNG'
import screen from './assets/screen.PNG'
import dext from './assets/dext.PNG'
import jup from './assets/jup.PNG'

import cmc from './assets/cmc.PNG'
import cg from './assets/cg.PNG'
import x from './assets/x.PNG'
import tg from './assets/tg.PNG'

import big from './assets/big.jpg'
import blank from './assets/blank.png'
import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  const [copied, setCopied] = useState(false);

  const textToCopy = "aPig1huDbdDTR9HmJBSHK5jiHrG3o2ZThdG4xPDpump";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Show message for 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };
  
  return (
    <div className="App">
      <body>
        <div className="contract" onClick={copyToClipboard}>
          {textToCopy}
        </div>
        {copied && <p className="copied-message">CA copied to clipboard</p>}
          <img src={big}  alt="background" className='big'></img>
          <img src={logo} alt="logo" className='logo'/>
          <img src={body} alt="body" className='body'></img>
          <div>
            {/* <p className='contract'>aPig1huDbdDTR9HmJBSHK5jiHrG3o2ZThdG4xPDpump</p> */}
            <p className='text1'>‎ "Source?"</p>
            <p className='text2'>"I made it up"</p>
            <p className='connect'>Connect:</p>
            <p className='bodytext'>It was revealed to me in a dream</p>
            <div className='footer'>
              <section>$SOURCE (Official token of spreading misinformation)</section>
            </div>
          </div>
          <div className='Stars'>
            <div id="stars" />
            <div id="stars2" />
            <div id="stars3" />
          </div>
          <div className='container'>
            <div className='item'>
              <a href='https://solscan.io/token/aPig1huDbdDTR9HmJBSHK5jiHrG3o2ZThdG4xPDpump' alt='contract'><img src={ss} alt='contracticon'/></a>
            </div>
            <div className='item'>
              <a href='https://jup.ag/swap/SOL-aPig1huDbdDTR9HmJBSHK5jiHrG3o2ZThdG4xPDpump' alt='chart'><img src={jup} alt='jupiter'/></a>
            </div>
            <div className='item'>
              <img src={blank} width='80px' alt='blank'/>
            </div>
            <div className='item'>
              <a href='https://dexscreener.com/solana/cjxsxkg5yjqps7zwqmjn2cehlyewixtaw4fszidfhzaw'><img src={screen} alt='dexscreener'/></a>
            </div>
            <div className='item'>
              <a href='https://www.dextools.io/app/en/solana/pair-explorer/CJxsxkg5yJqPS7ZwqMJN2cehLYEwixTAW4fSZiDFHZAW?t=1725486180263'><img src={dext} alt='dextools'/></a>
            </div>
          </div>
          <div className='links'>
            <div className='link'>
              <a href='https://t.me/sourcecto'><img src={tg} alt='telegram'/></a>
            </div>
            <div className='link'>
              <a href='https://x.com/sourcesolanacto'><img src={x} alt='x'/></a>
            </div>
          </div>
          <div className='links'>
            <div className='linky'>
              <a href='https://coinmarketcap.com/currencies/i-made-it-up/'><img src={cmc} alt='coinmarketcap'/></a>
            </div>
            <div className='linky'>
              <a href='https://www.coingecko.com/en/coins/i-made-it-up'><img src={cg} alt='coingecko'/></a>
            </div>
          </div>
        </body>
    </div>
  );
}

export default App;
